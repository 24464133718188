<style>
.el-dialog__body img{
  max-width: 100%;
}
</style>
<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        div(class="help-header") >>
          span(:class="projectName ? `test-c-a`: `test-c-b`" @click="onHelp()") ヘルプ
          span.test-c-b(v-if="projectName && ui.isSearch === false") >> {{ projectName }}

        el-input(placeholder="検索キーワードを入力" v-model="filterText")
        el-tree(
          class="filter-tree"
          default-expand-all
          :data="data"
          @node-click="handleNodeClick"
          node-key="id"
          :props="defaultProps"
          :filter-node-method="filterNode"
          ref="tree"
          empty-text="検索キーワードがありません"
        )
      div(class="content-right")
        PRBanner
    el-dialog(:title="popupValue.label" :visible.sync="dialogVisible" width="50%" height="100%"
      :before-close="handleClose")
      div(v-html="popupValue.describe")
      span(slot="footer" class="dialog-footer")
        el-button(type="primary" @click="dialogVisible = false") 確認
    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import PRBanner from '@/components/PRBanner';
import { getManual } from '@/api/system';

export default {
  name: "TopTermsManual",
  components: {
    PRBanner
  },
  data() {
    return {
      popupValue: '',
      filterText: '',
      dialogVisible: false,
      deleteUserDialogVisible: false,
      defaultProps: {
          children: 'children',
          label: 'label'
      },
      projectName: undefined,
      projectDetail: {
        key: undefined,
        value: undefined,
      },
      ui: {
        isSearch: false
      },
      inputText: '',
      dataSearch: undefined,
      data: [],
    }
  },
  props: {},
  computed: {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {},
  async created() {
    await this.init();
    const res = await getManual();
    const manualData = res.operation_manual_streamer.content.data;
    this.data = manualData.filter(v => v.label != '配信者')
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      this.dataSearch = [];
      for (let i = 0; i !== this.data.length; i++) {
        for (let j = 0; j !== this.data[i].value.length; j++) {
          this.dataSearch.push({
            key: `${this.data[i].key}>>${this.data[i].value[j].key}`,
            value: this.data[i].value[j].value,
            show: false,
          })
        }
      }
    },
    async onChange() {
      this.ui.isSearch = this.inputText.length > 0;
      if (this.ui.isSearch) {
        await this.init();
        this.dataSearch = this.dataSearch.filter(v => v.key.includes(this.inputText) || v.value.includes(this.inputText))
      } else {
        this.onHelp();
      }
    },
    onHelp() {
      this.projectName = undefined;
      this.projectDetail = {
        key: undefined,
        value: undefined,
      };
    },
    onProject(item) {
      this.projectName = item.key;
      this.projectDetail = item;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick (data) {
      if (data.children == undefined || data.children == null || data.children <= 0) {
        this.popupValue = data;
        this.dialogVisible = true;
      }
    },
    handleClose(done) {
      done();
    },
  }
};
</script>
